import React from 'react';

const RestaurantItem = ({ restaurant }) => {
  const { name, cuisine, address } = restaurant;

  const handleAddressClick = () => {
    // Construct the Google Maps URL with the address
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

    // Open the address in a new tab
    window.open(mapsUrl, '_blank');
  };

  const handleNameClick = () => {
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(name)}`;

    window.open(searchUrl, '_blank');
  };

  return (
    <li>
      <h3>
        <span className="name" onClick={handleNameClick}>
          <a href="#" onClick={(e) => e.preventDefault()}>{name}</a>
        </span>
      </h3>
      <p>Cuisine: {cuisine}</p>
      <p>
        Address:{' '}
        <span className="address" onClick={handleAddressClick}>
          <a href="#" onClick={(e) => e.preventDefault()}>{address}</a>
        </span>
      </p>
    </li>
  );
};

export default RestaurantItem;
