import React, { useState, useEffect } from 'react';
import RestaurantItem from './RestaurantItem';

const RestaurantList = ({ restaurants, searchActive }) => {
  const [openCategories, setOpenCategories] = useState([]);

  useEffect(() => {
    const currentHour = new Date().getHours();
    let defaultOpenCategories = [];

    if (currentHour >= 6 && currentHour < 9) {
      defaultOpenCategories = ['Breakfast'];
    } else if (currentHour >= 9 && currentHour < 15) {
      defaultOpenCategories = ['Lunch'];
    } else if (currentHour >= 15 || currentHour < 6) {
      defaultOpenCategories = ['Dinner'];
    }

    setOpenCategories(defaultOpenCategories);
  }, []);

  useEffect(() => {
    if (searchActive) {
      setOpenCategories((prevOpenCategories) => {
        // If search is active, expand all categories and suburbs
        const newOpenCategories = new Set(prevOpenCategories);
        restaurants.forEach((restaurant) => {
          const { category, suburb } = restaurant;
          newOpenCategories.add(category);
          newOpenCategories.add(`${category}-${suburb}`);
        });
        return Array.from(newOpenCategories);
      });
    } else {
      // If search is not active and the search box is empty, set categories based on time of day
      const currentHour = new Date().getHours();
      let defaultOpenCategories = [];

      if (currentHour >= 6 && currentHour < 9) {
        defaultOpenCategories = ['Breakfast'];
      } else if (currentHour >= 9 && currentHour < 15) {
        defaultOpenCategories = ['Lunch'];
      } else if (currentHour >= 15 || currentHour < 6) {
        defaultOpenCategories = ['Dinner'];
      }

      setOpenCategories(defaultOpenCategories);
    }
  }, [restaurants, searchActive]);

  const groupedRestaurants = restaurants.reduce((acc, restaurant) => {
    const { category, suburb } = restaurant;
    if (!acc[category]) {
      acc[category] = {};
    }
    if (!acc[category][suburb]) {
      acc[category][suburb] = [];
    }
    acc[category][suburb].push(restaurant);
    return acc;
  }, {});

  const toggleCategoryCollapse = (category) => {
    setOpenCategories((prevOpenCategories) =>
      prevOpenCategories.includes(category)
        ? prevOpenCategories.filter((cat) => cat !== category)
        : [...prevOpenCategories, category]
    );
  };

  const toggleSuburbCollapse = (category, suburb) => {
    const key = `${category}-${suburb}`;
    setOpenCategories((prevOpenCategories) =>
      prevOpenCategories.includes(key)
        ? prevOpenCategories.filter((cat) => cat !== key)
        : [...prevOpenCategories, key]
    );
  };

  return (
    <div>
      {Object.entries(groupedRestaurants).map(([category, suburbRestaurants]) => (
        <div key={category} className="restaurant-category">
          <h2
            onClick={() => toggleCategoryCollapse(category)}
            className={`category-title ${openCategories.includes(category) ? 'open' : ''}`}
          >
            {category}
            <span className="category-toggle">{openCategories.includes(category) ? ' -' : ' +'}</span>
          </h2>
          {openCategories.includes(category) && (
            <div className="suburb-wrapper">
              {Object.entries(suburbRestaurants).sort((a, b) => a[0].localeCompare(b[0])).map(([suburb, restaurants]) => (
                <div key={suburb} className={`suburb-content ${openCategories.includes(`${category}-${suburb}`) ? 'open' : ''}`}>
                  <h3
                    onClick={() => toggleSuburbCollapse(category, suburb)}
                    className={`suburb-title ${
                      openCategories.includes(`${category}-${suburb}`) ? 'open' : ''
                    }`}
                  >
                    {suburb}
                    <span className="suburb-toggle">
                      {openCategories.includes(`${category}-${suburb}`) ? ' -' : ' +'}
                    </span>
                  </h3>
                  {openCategories.includes(`${category}-${suburb}`) && (
                    <ul>
                      {restaurants.map((restaurant) => (
                        <RestaurantItem key={restaurant.id} restaurant={restaurant} />
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RestaurantList;
