import React, { useEffect, useState } from 'react';
import RestaurantList from './components/RestaurantList';
import parseCSV from './utils/csvParser';
import config from './config';
import Banner from './components/Banner';

const App = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filteredResults = restaurants.filter(
      (restaurant) =>
        restaurant.name.toLowerCase().includes(lowerCaseQuery) ||
        restaurant.cuisine.toLowerCase().includes(lowerCaseQuery) ||
        restaurant.suburb.toLowerCase().includes(lowerCaseQuery)
        // Add more fields to search on as needed
        // Example: restaurant.address.toLowerCase().includes(lowerCaseQuery)
    );

    setFilteredRestaurants(filteredResults);
    setSearchQuery(query);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching CSV file...');
        const data = await parseCSV(config.csvFilePath);
        console.log('Data:', data);
        setRestaurants(data);
        setFilteredRestaurants(data); // Initially, filtered results are the same as all restaurants
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="app">
      <Banner />
      <div className="header-container">
        <h2 className="city">Spots in Melbourne</h2>
        <input
          className="search-input"
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <RestaurantList restaurants={filteredRestaurants} searchActive={searchQuery !== ''} />
    </div>
  );
};

export default App;
