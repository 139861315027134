import React from 'react';
import bannerImage from '../images/banner.png';

const Banner = () => {
  return (
    <div className="banner">
      <img src={bannerImage} alt="Banner" />
    </div>
  );
};

export default Banner;