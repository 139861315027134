import Papa from 'papaparse';

const parseCSV = (fileURL) => {
  return new Promise((resolve, reject) => {
    Papa.parse(fileURL, {
      download: true,
      header: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          reject(results.errors);
        } else {
          const restaurants = [];
          results.data.forEach((row) => {
            const categories = row.category.split('|');
            categories.forEach((category) => {
              restaurants.push({ ...row, category, suburb: row.suburb.trim() });
            });
          });
          resolve(restaurants);
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        reject(error);
      },
    });
  });
};

export default parseCSV;